import React from 'react';
import styled from 'styled-components';
import { isBrowser, isMobile } from 'react-device-detect';
import LeafletMap from './LeafletMap/LeafletMap';

/* 
  Map using react-leaflet
  The map is improved (by me) to detect if user uses mobile device 
  to scrolling issues on mobile devices when map ocupies most of the screen  
  thus it's complicated to scroll the whole page
  The solution uses react-device-detect (https://www.npmjs.com/package/react-device-detect)
  which gives us isBrowser, isMobile
  and with this we can set dragging and tap
  Described here (https://www.notion.so/jurekskowron/Dodawanie-mapy-React-Leaflet-148e140238dc4fd098f8f51a0a94d42e#615c299a3be8455b974c299722ba2203)
*/

const Map = ({ className }) => (
  <LeafletMap
    className={className}
    showMarker
    shownPopup
    position={[50.94052, 17.29604]}
    zoom={17}
    googleMapsNavigateToUrl="https://www.google.com/maps/place/11+Listopada+14,+55-200+O%C5%82awa/@50.9406197,17.2949602,17.25z/data=!4m5!3m4!1s0x4710279fa9bd6e11:0x76a54ce88c7f2b33!8m2!3d50.940483!4d17.2959911"
    dragging={isBrowser}
    tap={isMobile}
  >
    <MarkerContent />
  </LeafletMap>
);

// content to be shown on popup on map marker
const MarkerContent = () => (
  <StyledMarker>
    <span className="h3Like">Medica</span>
  </StyledMarker>
);

export default Map;

const StyledMarker = styled.div`
  .block {
    display: block;
  }
`;
