import React from 'react';
import Obfuscate from 'react-obfuscate';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import MedicaImage from '../components/medicaImage';
import Map from '../components/map';

const IndexPage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <StyledBusinessCard className="container">
      <div className="infoWrapper">
        <h1>Przychodnia Lekarska Medica w&nbsp;Oławie</h1>
        <div className="aboutWrapper">
          <section>
            <h2 className="h4Like spacers">Kontakt</h2>
            <address>
              <span className="line">ul. 11 Listopada 14</span>
              <span className="line">55-200 Oława</span>
              <span className="line">
                tel.:{' '}
                <Obfuscate
                  style={{ display: 'inline-block' }}
                  tel="(71) 313 36 91"
                />
              </span>
              <span className="line">
                e-mail: <Obfuscate email="rejestracja@medica.olawa.pl" />
              </span>
            </address>
          </section>
          <section>
            <h2 className="h4Like spacers">Godziny otwarcia</h2>
            <span className="line">poniedziałek - piątek</span>
            <span className="line">8:00 - 18:00</span>
          </section>
        </div>
          {/* <section className="spacers">
            <a href="Warunki_udzielania_teleporad_03_2021_pelna.pdf">Warunki udzielania teleporad w Przychodni Lekarskiej Medica s.c.</a>
          </section> */}
      </div>
      <MedicaImage className="image" />
      <Map className="map" />
    </StyledBusinessCard>
  </Layout>
);

export default IndexPage;

const StyledBusinessCard = styled.section`
  --borderThickness: 0.8rem;
  --borderOuterRadius: 0;
  --borderInnerRadius: 0;
  --infoPadding: 0;

  border-radius: var(--borderOuterRadius);
  background: var(--background);
  box-shadow: var(--level-3);

  display: grid;
  grid-template-columns: 1fr;
  padding: var(--borderThickness);
  grid-gap: var(--borderThickness);

  min-width: 300px;

  .infoWrapper {
    padding: var(--infoPadding);
  }

  .map {
    grid-column: 1/-1;
    border-radius: 0 0 var(--borderInnerRadius) var(--borderInnerRadius);
  }

  @media screen and (min-width: 576px) {
    --borderThickness: 0.8rem;
    --borderOuterRadius: 1.4rem;
    --borderInnerRadius: 0.85rem;
    --infoPadding: 0.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 3fr 2fr;

    .image {
      border-radius: 0 var(--borderInnerRadius) 0 0;
    }
  }

  .aboutWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 2rem;
  }

  .spacers {
    margin-top: 2rem;
    margin-bottom: 0.7rem;
  }

  .line {
    display: block;
  }
`;
